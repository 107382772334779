//  引入拦截器
import axios from '@/common/js/request';

export default {
    //  获取列表
    getList(params) {
      return axios({
          url: '/scada/collectionPoint/pageNoBind',
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
          params
      })
    }
}