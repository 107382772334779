import {
	mapActions,
	mapState
} from 'vuex'
let indexMixin = {
	components: {},
	data() {
		return {
      stationTypeDict: {}
    }
	},
	created() {},

	async mounted() {
    
  },
	computed: {
		// 字
		...mapState('dict', ['stationSecondType'])
	},
	methods: {
		...mapActions('dict', ['getDropdown']),
    async isStringStartsWith1Or2(typeName) {
      await this.getDropdown('stationSecondType')
        const regex = /^[1]/;
        let arr1 = []// 供气站
        let arr2 = []// 用户站
        this.stationSecondType.forEach(e => {
            if(regex.test(e.value)) arr1.push(e)
            else arr2.push(e)
        })
        this.stationTypeDict = typeName=='供气站' ? arr1:arr2
        // let arr = typeName=='供气站' ? arr1:arr2
        // return arr;
    }
	}
}

export default indexMixin