//  引入拦截器
import axios from '@/common/js/request';

export default {
    //  获取列表
    getList(data) {
        return axios({
            url: '/scada/monitorPoint/selectList',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        })
    },
    //  新增监测点
    addMonitorPoint(data) {
        return axios({
            url: '/scada/monitorPoint/insertMonitorPoint',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        })
    },
    //  批量新增监测点位
    batchBindMonitorPoint(data) {
        return axios({
            url: '/scada/monitorPoint/batchBindCollectPoint',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        })
    },
    //  绑定采集点
    bindMonitorPoint(params) {
        return axios({
            url: '/scada/monitorPoint/bindCollectPoint',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params
        })
    },
    //  导出列表
    exportList(data) {
        return axios({
            url: '/scada/monitorPoint/exportList',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        })
    },
    //  查询监测点单条数据
    getMonitorPointInfo(params) {
        return axios({
            url: '/scada/monitorPoint/selectById',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params
        })
    },
    //  启用停用
    togglePointStatus(params) {
        return axios({
            url: '/scada/monitorPoint/togglePointStatus',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params
        })
    },
    //  解绑采集点
    unbindCollectPoint(params) {
        return axios({
            url: '/scada/monitorPoint/unbindCollectPoint',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params
        })
    },
    //  编辑监测点
    updateMonitorPoint(data) {
        return axios({
            url: '/scada/monitorPoint/updateById',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        })
    },
    //  导出
    exportList(data) {
        return axios({
            url: '/scada/monitorPoint/exportList',
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            responseType: 'blob', //改变接收的值类型
            data
        })
    },
}