<template>
  <el-dialog class="include-gis-dialog" :visible.sync="dialogForms" :close-on-press-escape="false"
    :close-on-click-modal="false" top="10vh" width="1283px" height="721px" title="站点信息" :destroy-on-close="true"
    @close="close()">
    <template slot="title">
      <div class="dialogTitles"><span></span> <b>监测点位列表</b></div>
    </template>
    <div class="tabs">
      <div class="topSearch">
        点位名称 <el-input class="selectBox" size="small" v-model="pageParams.pointName" placeholder="请输入"></el-input>
        数据类型 <el-select class="selectBox" size="small" v-model="pageParams.dataType" placeholder="请选择">
          <el-option v-for="item in dataType" :key="item.type" :label="item.name" :value="item.type"></el-option>
        </el-select>
        状态 <el-select class="selectBox" size="small" v-model="pageParams.pointStatus" placeholder="请选择">
          <el-option v-for="item in monitorPointStatus" :key="item.value" :label="item.label"
            :value="item.value"></el-option>
        </el-select>
        <div class="searchBtn">
          <el-button type="primary" size="small" @click="search">查询</el-button>
          <el-button type="info" plain size="small" @click="reset">重置</el-button>
        </div>
      </div>
      <div class="operateBtn">
        <el-button type="primary" size="small" @click="add()">添加</el-button>
        <el-button type="primary" size="small" icon="el-icon-circle-plus-outline" @click="monitor()">批量添加</el-button>
        <el-button type="primary" size="small" plain @click="exportDefault">导出</el-button>
      </div>
      <el-table v-if="head.length" :data="tableData" border class="tableStyle" height="calc(100vh - 451px)">
        <el-table-column label="序号" type="index" width="52">
        </el-table-column>
        <el-table-column v-for="col in head" :key="col.prop" :prop="col.prop" :label="col.label" align="left"
          show-overflow-tooltip :sortable="col.sorts" :width="col.widths">
          <template v-if="col.isBar" #default="{ row }"> </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="{ row }">
            <span class="linkBtn" style="margin-right:16px" @click="add(row.id)">编辑</span>
            <span v-if="row.pointStatusName.includes('停用') || row.pointStatus == 2" class="linkBtn"
              style="margin-right:16px" @click="changeStatus(row, '启用')">启用</span>
            <span v-if="row.pointStatusName.includes('启用') || row.pointStatus == 1" class="linkBtn"
              style="margin-right:16px" @click="changeStatus(row, '停用')">停用</span>
            <span v-if="row.collectPointId" class="linkBtn" @click="unMonitor(row.id)">解绑</span>
            <span v-else class="linkBtn" @click="monitor(row.id, row.dataType)">绑定</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination style="margin-top: 10px" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="pageParams.current" :page-sizes="[30, 50, 100]" :page-size="pageParams.size"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </el-dialog>
</template>

<script>
import monitoringApi from '@/apis/siteAdministration/monitoringApi'
import { exportMethod } from '@/common/js/exportExcel'
import indexMixin from '@/mixins/index.js'
import { mapActions, mapState } from 'vuex'
export default {
  mixins: [indexMixin],
  data() {
    return {
      pageParams: {
        size: 100,
        current: 1
      },
      surePageParams: {},
      tableData: [],
      head: [],
      head1: [
        { prop: 'pointName', label: '点位名称' },
        { prop: 'pointCode', label: '点位编号' },
        { prop: 'dataTypeName', label: '数据类型', widths: '100' },
        { prop: 'importantName', label: '重点检测' },
        { prop: 'supplyCalculateName', label: '参与供气量计算', widths: '120' },
        { prop: 'pressureTypeName', label: '压力类型' },
        { prop: 'unit', label: '单位' },
        { prop: 'description', label: '监测位置描述', widths: '110' },
        { prop: 'pointStatusName', label: '状态' },
        { prop: 'collectPointName', label: '远传采集点', widths: '100' },
        { prop: 'equipmentName', label: '使用设备' }
      ],
      head2: [
        { prop: 'pointName', label: '点位名称' },
        { prop: 'pointCode', label: '点位编号' },
        { prop: 'dataTypeName', label: '数据类型', widths: '100' },
        { prop: 'importantName', label: '重点检测' },
        { prop: 'pressureTypeName', label: '压力类型' },
        { prop: 'unit', label: '单位' },
        { prop: 'description', label: '监测位置描述', widths: '110' },
        { prop: 'pointStatusName', label: '状态' },
        { prop: 'collectPointName', label: '远传采集点', widths: '100' },
        { prop: 'equipmentName', label: '使用设备' }
      ],
      total: 0,
      scadaStationId: null,
      typeName: '',
      dialogForms: false,
      info: '弹框信息',
      stationTypeDict: []

    }
  },
  computed: {
    ...mapState('dict', ['dataType', 'monitorPointStatus'])
  },
  created() {
    console.log('created');
  },
  mounted() {

  },
  methods: {
    ...mapActions('dict', ['getDropdown', 'getDataType']),
    async open(scadaStationId, typeName) {
      await this.getDropdown('monitorPointStatus,important')
      await this.getDataType()
      if(typeName.includes('供气站')) this.head = this.head1
      else this.head = this.head2
      this.scadaStationId = scadaStationId
      this.typeName = typeName
      this.surePageParams = JSON.parse(JSON.stringify(this.pageParams))
      this.getList(this.pageParams)
      this.dialogForms = true
    },
    async getList(pageParams) {
      let { scadaStationId } = this
      let params = JSON.parse(JSON.stringify(pageParams))
      params.scadaStationId = scadaStationId
      params.dataFlag = 'scada'
      monitoringApi.getList(params).then(res => {
        if (res.code == 200) {
          res.data.list.forEach(item => {
            item.collectPointName = item.collectPointName || '-'
            item.equipmentName = item.equipmentName || '-'
            item.pressureTypeName = item.pressureTypeName || '-'
            item.supplyCalculateName = item.supplyCalculateName || '-'
          })
          this.tableData = res.data.list
          this.total = res.data.total
        } else this.$message.error(res.msg)
      })
    },
    search() {
      this.pageParams.current = 1
      this.surePageParams = JSON.parse(JSON.stringify(this.pageParams))
      this.getList(this.pageParams)
    },
    reset() {
      this.pageParams = {
        current: 1,
        size: 100
      }
      this.surePageParams = JSON.parse(JSON.stringify(this.pageParams))
      this.getList(this.pageParams)
    },
    add(id) {
      let { scadaStationId } = this
      this.$parent.$refs.addMonitorPoint.open(id, scadaStationId, this.typeName)
    },
    /**
     * Description  绑定/批量绑定采集点
     * @author slx
     * @date 2024-01-06
     * @param {any} id
     * @returns {any}
     */
    monitor(id, dataType) {
      let { scadaStationId } = this
      this.$parent.$refs.bind.open(id, scadaStationId, this.typeName, dataType)
    },
    /**
     * Description  解绑监测点位
     * @author slx
     * @date 2024-01-06
     * @param {any} id
     * @returns {any}
     */
    unMonitor(id) {
      this.$confirm('确认解绑远传采集点？', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        monitoringApi.unbindCollectPoint({ id }).then((res) => {
          if (res.code == 200) {
            this.$message.success(res.msg)
            this.search()
          } else this.$message.error(res.msg)
        })
      }).catch(action => { });
    },
    /**
     * Description  修改状态
     * @author slx
     * @date 2024-01-06
     * @param {any} row 行数据
     * @param {any} operate 启用或停用
     * @returns {any}
     */
    async changeStatus(row, operate) {
      let { id } = row
      let { label, value } = await this.monitorPointStatus.find(e => e.value != row.pointStatus)
      if (value) {
        this.$confirm(`确认${operate}此条数据？`, '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(() => {
          monitoringApi.togglePointStatus({ id, pointStatus: value }).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.msg)
              this.search()
            } else this.$message.error(res.msg)
          })
        }).catch(action => { });
      }
    },
    handleSizeChange(e) {
      this.pageParams.size = e;
      this.surePageParams = JSON.parse(JSON.stringify(this.pageParams))
      this.getList(this.pageParams);
    },
    handleCurrentChange(e) {
      this.pageParams.current = e;
      this.surePageParams = JSON.parse(JSON.stringify(this.pageParams))
      this.getList(this.pageParams);
    },
    close() {
      Object.assign(this.$data, this.$options.data())
    },
    exportDefault() {
      let params = JSON.parse(JSON.stringify(this.surePageParams))
      let { scadaStationId } = this
      params.scadaStationId = scadaStationId
      params.current = 0
      params.size = -1
      monitoringApi.exportList(params).then(res => {
        exportMethod(res, '监测点位')
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.linkBtn {
  cursor: pointer;
  color: #1082FF;
}

.tabs {
  background: #fff;
  padding: 16px 20px 8px 20px;
  height: 660px;

  .topSearch {
    color: #0F0F0F;
    border-bottom: 1px solid #E3E3E3;
    padding: 0 10px 16px;

    .selectBox {
      width: 200px;
      margin: 0 30px 0 4px;
    }

    .searchBtn {
      display: inline-block;
    }
  }

  .operateBtn {
    margin: 16px 0 10px;
  }
}
</style>