<template>
  <el-dialog class="include-gis-dialog" :visible.sync="dialogForms" :close-on-press-escape="false"
    :close-on-click-modal="false" top="10vh" width="1026px" title="站点信息" :destroy-on-close="true" @close="close()">
    <template slot="title">
      <div class="dialogTitles"><span></span> <b>关联GIS站点</b></div>
    </template>
    <div class="body">
      <div class="body-left">
        <div class="left-item">
          <span class="item-label">站点名称</span>
          <div class="item-comtent">
            <el-input size="small" placeholder="请输入" v-model="pageParams.stationName"></el-input>
          </div>
        </div>
        <div class="left-item">
          <span class="item-label">站点类型</span>
          <div class="item-comtent">
            <el-select size="small" placeholder="请选择" v-model="pageParams.stationKind" style="margin-right: 10px;"
              v-if="stationTypeName == '供气站'">
              <el-option v-for="item in gasStationDict" :key="item.code" :label="item.name"
                :value="item.code"></el-option>
            </el-select>
            <el-select size="small" placeholder="请选择" v-model="pageParams.stationKind" style="margin-right: 10px;" v-else>
              <el-option v-for="item in userStationDict" :key="item.code" :label="item.name"
                :value="item.code"></el-option>
            </el-select>
            <div>
              <el-button type="primary" size="small" @click="search">查询</el-button>
              <el-button type="info" plain size="small" @click="reset">重置</el-button>
            </div>
          </div>
        </div>
        <el-table ref="DataTable" :data="tableData" border class="tableStyle" highlight-current-row
          height="calc(100vh - 400px)" @row-click="rowClick">
          <el-table-column v-for="col in head" :key="col.prop" :prop="col.prop" :label="col.label" align="left"
            show-overflow-tooltip :sortable="col.sorts" :width="col.widths">
            <template v-if="col.isBar" #default="{ row }">
              <div v-if="col.prop == 'gisStationId'" class="linkBtn">
                <span v-if="row.gisStationId" class="linkBtn">GIS定位</span>
                <span v-else class="linkBtn">关联GIS</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="{ row }">
              <span class="linkBtn" style="margin-right:16px" @click="associate(row)">关联</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="gis"  v-if="dialogForms">
        <map-box ref="mapBox"></map-box>
        <!-- <station-location ref="stationLocation"></station-location> -->
      </div>
    </div>
  </el-dialog>
</template>

<script>
import gasStationApi from '@/apis/siteAdministration/gasStationApi';
import indexMixin from '@/mixins/index.js';
import { mapActions, mapState } from 'vuex';
import stationLocation from './stationLocation.vue';
import mapBox from './mapBox.vue'
export default {
  mixins: [indexMixin],
  components: {
    stationLocation,
    mapBox
  },
  data() {
    return {
      id: null,
      dialogForms: false,
      info: '弹框信息',
      stationTypeName: '供气站',
      stationTypeValue: null,
      scadaStationInfo: {},
      pageParams: {
        size: -1,
        current: 1
      },
      ruleForm: {},
      tableData: [],
      head: [
        { prop: 'gisStationName', label: 'GIS站点名称' },
        { prop: 'stationKind', label: '站点类型' }
      ],
    }
  },
  computed: {
    ...mapState('dict', ['gasStationDict', 'userStationDict', 'stationType'])
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions('dict', ['getDropdown', 'getDict']),
    search() {
      this.getList(this.pageParams)
    },
    rowClick(row, column, event) {
      this.currentRow = row
      this.$nextTick(() => {
        this.$refs.mapBox.setMap({},row.gisStationLocation)
      })
    },
    reset() {
      this.pageParams = {
        current: 1,
        size: -1
      }
      this.getList(this.pageParams)
    },
    async open(scadaStationInfo, stationTypeName) {
      await this.getDropdown('stationType')
      let { value } = this.stationType.find(item => { return item.label.includes(stationTypeName) })
      value = value || 1
      this.scadaStationInfo = scadaStationInfo
      this.stationTypeName = stationTypeName
      this.stationTypeValue = value
      let params = {
        parentCode: stationTypeName == '供气站' ? '100019' : '100032',
        level: 1
      }
      this.getDict(params)
      this.dialogForms = true
      this.getList(this.pageParams)
    },
    close() {
      this.dialogForms = false
    },
    async getList(pageParams) {
      let params = JSON.parse(JSON.stringify(pageParams))
      params.stationType = this.stationTypeValue
      let { code, data } = await gasStationApi.getGisStationList(params)
      this.tableData = data.list || []
      this.total = data.total
      this.$nextTick(() => {
        if(data.list.length>0) {
          this.currentRow = data.list[0]
          this.$refs.DataTable.setCurrentRow(this.currentRow)
          this.$refs.mapBox.setMap({},data.list[0].gisStationLocation)
        }
        // this.$refs.mapBox.setMap({},'POINT(125.780484 41.68471)')
      })
    },
    /**
     * Description 关联（绑定scada场站与gis场站）
     * @author slx
     * @date 2024-01-04
     * @param {any} gisStationId gis场站id
     * @returns {any}
     */
    associate(gisStationTnfo) {
      let { scadaStationId, stationName } = this.scadaStationInfo
      let { gisStationId, gisStationName } = gisStationTnfo
      this.$confirm(`确定将站点【${stationName}】与GIS站点【${gisStationName}】进行关联?`, '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        let params = { gisStationId, scadaStationId }
        gasStationApi.bindGisScada(params).then((res) => {
          if (res.code == 200) {
            this.dialogForms = false
            this.$parent.reset()
            this.$message.success(res.msg)
          } else this.$message.error(res.msg)
        })
      }).catch(action => {});
    },
  }
}
</script>

<style lang="scss" scoped>
.linkBtn {
  cursor: pointer;
  color: #1082FF;
}

.body {
  display: flex;

  .body-left {
    flex: 50;
    padding: 20px;

    .left-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 16px;

      .item-label {
        flex: 2;
      }

      .item-comtent {
        flex: 11;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .gis {
    flex: 63;
    padding: 20px;
    border-left: 1px solid #ebebeb;
  }
}
</style>