<template>
  <el-dialog class="include-gis-dialog" :visible.sync="dialogForms" :close-on-press-escape="false"
    :close-on-click-modal="false" top="10vh" width="790px" height="721px" title="站点信息" :destroy-on-close="true"
    @close="close()">
    <template slot="title">
      <div class="dialogTitles"><span></span> <b>远传采集点列表</b></div>
    </template>
    <div class="tabs">
      <div class="topSearch" v-if="id">
        采集点名称 <el-input class="selectBox" size="small" v-model="pageParams.name" placeholder="请输入"></el-input>
        <div class="searchBtn">
          <el-button type="primary" size="small" @click="search">查询</el-button>
          <el-button type="info" plain size="small" @click="reset">重置</el-button>
        </div>
      </div>
      <div class="topSearch" v-else>
        采集点名称 <el-input class="selectBox" size="small" v-model="pageParams.name" placeholder="请输入"></el-input>
        数据类型 <el-select class="selectBox" size="small" v-model="pageParams.dataType" placeholder="请选择">
          <el-option v-for="item in dataType" :key="item.type" :label="item.name" :value="item.type"></el-option>
        </el-select>
        <div class="searchBtn">
          <el-button type="primary" size="small" @click="search">查询</el-button>
          <el-button type="info" plain size="small" @click="reset">重置</el-button>
        </div>
      </div>
      <el-table :data="tableData" border class="tableStyle" :height="id ? 'calc(100vh - 451px)' : 'calc(100vh - 410px)'">
        <el-table-column v-for="col in head" :key="col.prop" :prop="col.prop" :label="col.label" align="left"
          show-overflow-tooltip :sortable="col.sorts" :width="col.widths">
          <!-- <template v-if="col.isBar" #default="{ row }">
          </template> -->
        </el-table-column>
        <el-table-column label="操作" width="70">
          <template slot-scope="{ row }">
            <el-radio v-if="id" v-model="selectVal" :label="row">&nbsp;</el-radio>
            <el-checkbox v-else v-model="row.checked">&nbsp;</el-checkbox>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination v-if="id" style="margin-top: 10px" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="pageParams.current" :page-sizes="[30, 50, 100]"
        :page-size="pageParams.size" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <div slot="footer" class="dialog-footer">
      <!-- 按钮顺序 重置 取消 保存 -->
      <el-button size="medium" @click="close()">取消</el-button>
      <el-button type="primary" size="medium" @click="save">{{ id ? '确定' : '添加' }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import collectionPointApi from '@/apis/siteAdministration/collectionPointApi'
import monitoringApi from '@/apis/siteAdministration/monitoringApi'
import indexMixin from '@/mixins/index.js'
import { mapActions, mapState } from 'vuex'
export default {
  mixins: [indexMixin],
  data() {
    return {
      pageParams: {
        size: 100,
        current: 1
      },
      tableData: [],
      head: [
        { prop: 'protocol', label: '采集点来源' },
        { prop: 'name', label: '采集点名称' },
        { prop: 'tableName', label: '采集点编号' },
        { prop: 'dataTypeName', label: '数据类型' }
      ],
      total: 0,
      id: null,
      scadaStationId: null,
      dataTypeVal: null,
      selectVal: null,
      typeName: '',
      dialogForms: false,
      info: '弹框信息'

    }
  },
  computed: {
    ...mapState('dict', ['dataType'])
  },
  created() {
    console.log('created');
  },
  mounted() { },
  methods: {
    ...mapActions('dict', ['getDropdown', 'getDataType']),
    async open(id, scadaStationId, typeName, dataTypeVal) {
      await this.getDropdown('important')
      await this.getDataType()
      this.typeName = typeName
      this.id = id
      this.scadaStationId = scadaStationId
      this.dataTypeVal = dataTypeVal
      if (id) {
        this.pageParams = {
          size: 100,
          current: 1
        }
      } else {
        this.pageParams = {
          size: 9999999,
          current: 1
        }
      }
      this.$nextTick(() => {
        this.getList(this.pageParams)
        this.dialogForms = true
      })
    },
    /**
     * Description  获取列表
     * @author slx
     * @date 2024-01-09
     * @param {any} pageParams 请求参数对象
     * @returns {any}
     */
    async getList(pageParams) {
      let params = JSON.parse(JSON.stringify(pageParams))
      if(this.id) params.dataType = this.dataTypeVal
      collectionPointApi.getList(params).then(res => {
        if (res.code == 200) {
          this.tableData = res.data.records
          this.total = res.data.total
          this.setSelect()
        } else this.$message.error(res.msg)
      })
    },
    save() {
      let { id, selectVal, scadaStationId } = this
      if (id) {
        let msg = `确认绑定远传采集点【${selectVal.name}】？`
        if (!selectVal) return this.$message.error('请选择采集点')
        this.bindPoint(msg, { id, collectPointId: selectVal.id }, 'bindMonitorPoint')
      } else {
        let selectArr = this.tableData.filter(item => { return item.checked == true })
        if (!selectArr.length > 0) return this.$message.error('请选择采集点')
        let collectPointIds = selectArr.map(item => { return item.id })
        let msg = '确认根据选择的远传采集点信息，进行批量监测点位添加?'
        this.bindPoint(msg, { collectPointIds, scadaStationId }, 'batchBindMonitorPoint')
      }
    },
    bindPoint(msg, params, type) {
      this.$confirm(msg, '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        monitoringApi[type](params).then((res) => {
          if (res.code == 200) {
            this.$message.success(res.msg)
            this.$parent.$refs.monitoring.reset()
            this.close()
          } else this.$message.error(res.msg)
        })
      }).catch(action => { });
    },
    search() {
      this.pageParams.current = 1
      this.getList(this.pageParams)
    },
    /**
     * Description  重新获取列表的时候清空已选数据，有id是单选，无id是多选
     * @author slx
     * @date 2024-01-09
     * @returns {any}
     */
    setSelect() {
      if (this.id) {
        this.$set(this, 'selectVal', null)
      } else {
        this.tableData.forEach(e => { this.$set(e, 'checked', false) })
      }
    },
    reset() {
      if (this.id) {
        this.pageParams = {
          size: 100,
          current: 1
        }
      } else {
        this.pageParams = {
          size: 9999999,
          current: 1
        }
      }
      this.$nextTick(() => {
        this.getList(this.pageParams)
      })
    },
    handleSizeChange(e) {
      this.pageParams.size = e;
      this.getList(this.pageParams);
    },
    handleCurrentChange(e) {
      this.pageParams.current = e;
      this.getList(this.pageParams);
    },
    close() {
      Object.assign(this.$data, this.$options.data())
    },
  }
}
</script>

<style lang="scss" scoped>
.linkBtn {
  cursor: pointer;
  color: #1082FF;
}

.tabs {
  background: #fff;
  padding: 16px 20px 8px 20px;
  height: 594px;

  .topSearch {
    color: #0F0F0F;
    border-bottom: 1px solid #E3E3E3;
    padding: 0 10px 16px;

    .selectBox {
      width: 200px;
      margin: 0 30px 0 4px;
    }

    .searchBtn {
      display: inline-block;
    }
  }

  .operateBtn {
    margin: 16px 0 10px;
  }
}
</style>