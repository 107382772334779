<template>
  <el-dialog class="single-row-dialog" :visible.sync="addMonitorDialog" :close-on-press-escape="false"
    :close-on-click-modal="false" top="10vh" width="340px" title="监测点位信息" :destroy-on-close="true" @close="close()">
    <template slot="title">
      <div class="dialogTitles"><span></span> <b>{{ id ? '编辑' : '添加' }}监测点位信息</b></div>
    </template>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top" :inline="true" class="addForm">
      <el-row>
        <el-col :span="24">
          <el-form-item label="点位名称" prop="pointName">
            <el-input v-model="ruleForm.pointName" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="数据类型" prop="dataType">
            <el-select v-model="ruleForm.dataType" placeholder="请选择" @change="dataTypeChange">
              <el-option v-for="item in dataType" :key="item.type" :label="item.name" :value="item.type"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="重点检测" prop="important">
            <el-select v-model="ruleForm.important" placeholder="请选择">
              <el-option v-for="item in scadaMonitorPointImportant" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="supplyCalculateShow">
          <el-form-item label="参与供气量计算" prop="supplyCalculate">
            <el-select v-model="ruleForm.supplyCalculate" placeholder="请选择">
              <el-option v-for="item in scadaMonitorPointImportant" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="pressureTypeShow">
          <el-form-item label="压力类型" prop="pressureType">
            <el-select v-model="ruleForm.pressureType" placeholder="请选择">
              <el-option v-for="item in monitorPointPressureType" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="监测位置描述">
            <el-input type="textarea" v-model="ruleForm.description" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <!-- 按钮顺序 重置 取消 保存 -->
      <el-button size="medium" @click="close()">取消</el-button>
      <el-button type="primary" size="medium" @click="save">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import monitoringApi from '@/apis/siteAdministration/monitoringApi';
import indexMixin from '@/mixins/index.js';
import { mapActions, mapState } from 'vuex';
export default {
  mixins: [indexMixin],
  data() {
    return {
      id: null,
      scadaStationId: null,
      supplyCalculateShow: false,
      pressureTypeShow: false,
      addMonitorDialog: false,
      typeName: '',
      info: '弹框信息',
      ruleForm: {
        pressureType: null,
      },
      rules: {
        pointName: [{ required: true, message: '请输入点位名称', trigger: 'blur' }],
        dataType: [{ required: true, message: '请选择数据类型', trigger: 'change' }],
        important: [{ required: true, message: '请选择重点检测', trigger: 'change' }],
        supplyCalculate: [{ required: true, message: '请选择参与供气量计算', trigger: 'change' }],
        pressureType: [{ required: true, message: '请选择压力类型', trigger: 'change' }],
      }

    }
  },
  computed: {
    ...mapState('dict', ['dataType', 'stationType', 'scadaMonitorPointImportant', 'monitorPointPressureType', 'supplyCalculate'])
  },
  created() {
    console.log('created');
  },
  mounted() {


  },
  methods: {
    ...mapActions('dict', ['getDropdown', 'getDataType']),
    async open(id, scadaStationId, typeName) {
      await this.getDropdown('stationType,scadaMonitorPointImportant,monitorPointPressureType,supplyCalculate')
      await this.getDataType()
      this.typeName = typeName
      this.id = id
      this.scadaStationId = scadaStationId
      if (id) {
        monitoringApi.getMonitorPointInfo({ id }).then(res => {
          if (res.code == 200) {
            this.ruleForm = {
              ...this.ruleForm,
              ...res.data
            }
            this.setDefaultValues(this.ruleForm.dataType, typeName)
          } else this.$message.error(res.msg)
        })
      } else {
        let { scadaMonitorPointImportant, dataType } = this
        this.ruleForm = {
          ...this.ruleForm,
          dataType: dataType.length ? dataType[0].type : null,
          important: scadaMonitorPointImportant.length ? scadaMonitorPointImportant[0].value : null
        }
        this.$nextTick(() => {
          this.setDefaultValues(this.ruleForm.dataType, typeName)
          this.$forceUpdate()
        })
      }
      this.addMonitorDialog = true
    },
    dataTypeChange(e) {
      let typeName = this.typeName
      this.setDefaultValues(e, typeName)
    },
    /**
     * Description  设置默认值
     * @author slx
     * @date 2024-01-05
     * @param {any} dataType
     * @returns {any}
     */
    async setDefaultValues(val, typeName) {
      let { dataType, supplyCalculate, monitorPointPressureType, id } = this
      let res1 = await dataType.find(item => { return item.type == val })
      if (res1 && res1.name.includes('总累标况') && typeName.includes('供气站')) {
        if(!id) {
          let res2 = await supplyCalculate.find(item => { return item.label.includes('否') })
          if (res2) this.$set(this.ruleForm, 'supplyCalculate', res2.value)
          this.$forceUpdate()
        }
        this.$set(this, 'supplyCalculateShow', true)
      } else {
        this.supplyCalculateShow = false
        this.ruleForm.supplyCalculate = null
        this.ruleForm.supplyCalculateName = null
      }
      if (res1 && res1.name.includes('压力')) {
        if(!id) {
          let res2 = await monitorPointPressureType.find(item => { return item.label.includes('普通压力') })
          if (res2) this.$set(this.ruleForm, 'pressureType', res2.value)
          this.$forceUpdate()
        }
        this.$set(this, 'pressureTypeShow', true)
      } else {
        this.pressureTypeShow = false
        this.ruleForm.pressureType = null
        this.ruleForm.pressureTypeName = null
      }
    },
    close() {
      this.ruleForm = {pressureType: null}
      this.$refs['ruleForm'].resetFields()
      this.addMonitorDialog = false
    },
    save() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          let params = JSON.parse(JSON.stringify(this.ruleForm))
          params.scadaStationId = this.scadaStationId
          params.pointSource = 1
          if (this.id) {
            monitoringApi.updateMonitorPoint(params).then(res => {
              if (res.code == 200) {
                this.$message.success(res.msg)
                this.close()
                this.$parent.$refs.monitoring.reset()
              } else this.$message.error(res.msg)
            })
          } else {
            monitoringApi.addMonitorPoint(params).then(res => {
              if (res.code == 200) {
                this.$message.success(res.msg)
                this.close()
                this.$parent.$refs.monitoring.reset()
              } else this.$message.error(res.msg)
            })
          }
        } else { }
      })
    },
  }
}
</script>

<style lang="scss" scoped></style>