<template>
  <el-dialog class="location-dialog" :visible.sync="dialogForms" :close-on-press-escape="false"
    :close-on-click-modal="false" top="10vh" width="1026px" title="站点信息" :destroy-on-close="true" @close="close()">
    <template slot="title">
      <div class="dialogTitles"><span></span> <b>定位</b></div>
    </template>
    <div style="width: 100%;height: 613px" v-if="dialogForms"><map-box ref="mapBox"></map-box></div>
    <!-- <div id="mapAdd" style="width: 100%;height: 613px">

    </div> -->
    <el-button v-if="obj.gisStationId" class="cancelBtn" type="primary" size="small" @click="cancel()">取消关联</el-button>
  </el-dialog>
</template>

<script>
import gasStationApi from '@/apis/siteAdministration/gasStationApi';
import mapBox from './mapBox.vue'
export default {
  components: { mapBox },
  data() {
    return {
      dialogForms: false,
      obj: {}
    }
  },
  mounted() {
    // this.open()
  },
  methods: {
    open(obj, lonlat) {
      this.dialogForms = true
      this.obj = obj
      this.$nextTick(() => {
        this.$refs.mapBox.setMap(obj, lonlat)
      })
    },
    close() {

    },
    /**
     * Description  取消关联
     * @author slx
     * @date 2024-01-04
     * @returns {any}
     */
    cancel() {
      let { gisStationId } = this.obj
      this.$confirm('确认解除与GIS站点绑定关系？', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        gasStationApi.unBindGisScada({ gisStationId }).then((res) => {
          if (res.code == 200) {
            this.dialogForms = false
            this.$parent.reset()
            this.$message.success(res.msg)
          } else this.$message.error(res.msg)
        })
      }).catch(action => {});
    }
  }
}
</script>

<style lang="scss" scoped>
.cancelBtn {
  position: absolute;
  top: 100px;
  right: 40px;
}
</style>