<template>
  <div style="width: 100%;height: 100%">
    <div id="mapAdd" style="width: 100%;height: 100%">

    </div>
        <location-lengd ref="LocationLengd" style="position: absolute;right: 10px;bottom: 20px;"></location-lengd>
  </div>
</template>

<script>
import { formatLocation } from '@/views/Gis/utils/geoTool'
import LocationLengd from '@/views/gisbook/locationLengd.vue'
export default {
  components: {
    LocationLengd
  },
  data() {
    return {}
  },
  mounted() {
  },
  destroyed() {
    console.log(this.map, 'destroy');
    if (this.map) this.map.destroy()
   
  },
  methods: {
    setMap(obj, lonlat) {
      let position = formatLocation(lonlat, 'Point')
      this.$nextTick(() => {
        console.log(this.map, '------------------1');
        if (this.map) this.map.setCenter(position)
        if (!this.map) {
          this.map = new AMap.Map("mapAdd", {
            resizeEnable: true,
            center: position,
            zoom: 14,
            pitch: 0,
            rotation: 0,
            zoom: 17,
            zooms: [0, 20],
            maxZoom: 20,
            labelzIndex: 1,
            pitchEnable: false,
            rotateEnable: false,
            expandZoomRange: true, //调整最大缩放级数
            showBuildingBlock: false,
            animateEnable: false,
            jogEnable: false,
          });
          this.$refs.LocationLengd.getwms()
        }
        console.log(this.map, '------------------2');
        // return
        if (obj.showType === 'line') {
          if (this.markerHighlightA) {
            this.map.remove(this.markerHighlightA)
          }
          this.markerHighlightA = new AMap.Polyline({
            path: obj.lineArray,
            isOutline: true,
            outlineColor: '#1A5F48',
            borderWeight: 3,
            strokeColor: '#00FF7F',
            strokeOpacity: 1,
            strokeWeight: 3,
            strokeStyle: 'solid',
            lineJoin: 'round',
            lineCap: 'round',
            zIndex: 150,
            extData: ''
          })
          this.map.add(this.markerHighlightA)
        } else {
          if (this.markerHighlight) {
            this.map.remove(this.markerHighlight)
          }
          this.markerHighlight = new AMap.Marker({
            content:
              '<div class="setCommon"><i class="setPoint"></i></div>',
            offset: new AMap.Pixel(-15, -25),
            position: position,
            extData: ''
          })
          this.map.add(this.markerHighlight)
        }
        // this.openEquipmentInfo(obj, position)
      })
      // this.getList(obj.id)
      // this.stationId = obj.id
      // this.stationName = obj.stationName
    }
  }
}
</script>

<style lang="scss"></style>