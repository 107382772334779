<template>
    <el-dialog :visible.sync="dialogForms" :close-on-press-escape="false" :close-on-click-modal="false" top="10vh"
        width="470px" title="站点信息" :destroy-on-close="true" @close="close()">
        <template slot="title">
            <div class="dialogTitles"><span></span> <b>{{ id ? '编辑' : '添加' }}站点信息</b></div>
        </template>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top" :inline="true" class="addForm">
            <el-row :gutter="30">
                <el-col :span="12">
                    <el-form-item label="站点名称" prop="stationName">
                        <el-input v-model="ruleForm.stationName" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="站点类型" prop="stationSecondType">
                        <el-select v-model="ruleForm.stationSecondType" placeholder="请选择">
                            <el-option v-for="item in stationTypeDict" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="燃气介质" prop="gasType">
                        <el-select v-model="ruleForm.gasType" placeholder="请选择">
                            <el-option v-for="item in gasType" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="权属单位">
                        <el-input v-model="ruleForm.ownershipCompany" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="建设日期">
                        <el-date-picker v-model="ruleForm.buildDate" type="date" format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                        <!-- <el-input v-model="ruleForm.buildDate"></el-input> -->
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="设计规模" prop="designScale">
                        <el-input v-model="ruleForm.designScale" placeholder="请输入"><template
                                slot="append">Nm³/h</template></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="使用状态" prop="usingStatus">
                        <el-select v-model="ruleForm.usingStatus" placeholder="请选择">
                            <el-option v-for="item in usingStatus" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-if="typeName.includes('用户站')">
                    <el-form-item label="重点检测">
                        <el-select v-model="ruleForm.important" placeholder="请选择">
                            <el-option v-for="item in scadaStationImportant" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="位置描述">
                        <el-input type="textarea" v-model="ruleForm.positionDes" rows="1" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="备注">
                        <el-input type="textarea" v-model="ruleForm.remark" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <!-- 按钮顺序 重置 取消 保存 -->
            <el-button size="medium" @click="close()">取消</el-button>
            <el-button type="primary" size="medium" @click="save">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
import gasStationApi from '@/apis/siteAdministration/gasStationApi'
import indexMixin from '@/mixins/index.js'
import { mapActions, mapState } from 'vuex'
export default {
    mixins: [indexMixin],
    data() {
        return {
            id: null,
            typeName: '',
            dialogForms: false,
            info: '弹框信息',
            ruleForm: {},
            rules: {
                stationName: [{ required: true, message: '请输入站点名称', trigger: 'blur' }],
                stationSecondType: [{ required: true, message: '请选择站点类型', trigger: 'change' }],
                designScale: [
                    {
                        pattern: /^(?:\d{0,5}|0(?!(?:\.0{0,6})?$))?(?:\.\d{0,4})?$/,
                        message: '只能输入小数点前5位，后4位的数字',
                        trigger: 'blur'
                    }
                ],
                gasType: [{ required: true, message: '请选择燃气介质', trigger: 'change' }],
                usingStatus: [{ required: true, message: '请选择使用状态', trigger: 'change' }],
            }

        }
    },
    computed: {
        ...mapState('dict', ['stationType', 'usingStatus', 'gasType', 'scadaStationImportant'])
    },
    created() {
        console.log('created');
    },
    mounted() {},
    methods: {
        ...mapActions('dict', ['getDropdown']),
        async open(id, typeName) {
            await this.getDropdown('stationType,usingStatus,gasType,scadaStationImportant')
            await this.isStringStartsWith1Or2(typeName)
            this.id = id
            this.typeName = typeName
            let {value} = this.stationType.find(item => {return item.label.includes(typeName)})
            value = value || 1
            if (id) {
                gasStationApi.getStationInfo({ id }).then(res => {
                    if (res.code == 200) {
                        this.ruleForm = {
                            ...this.ruleForm,
                            ...res.data
                        }
                    } else this.$message.error(res.msg)
                })
            } else {
                let { gasType, usingStatus, scadaStationImportant } = this
                let index = scadaStationImportant.findIndex(item => item.label.includes('否'))
                this.ruleForm = {
                    ...this.ruleForm,
                    stationSecondType: this.stationTypeDict.length ? this.stationTypeDict[0].value : null,
                    gasType: gasType.length ? gasType[0].value : null,
                    usingStatus: usingStatus.length ? usingStatus[0].value : null,
                    important: scadaStationImportant.length && typeName.includes('用户站') ? scadaStationImportant[index].value : null,
                    stationType: value
                }
                this.$forceUpdate()
            }
            this.dialogForms = true
        },
        close() {
            this.ruleForm = {}
            this.$refs['ruleForm'].resetFields()
            this.dialogForms = false
        },
        save() {
            this.$refs['ruleForm'].validate(valid => {
                if (valid) {
                    let params = JSON.parse(JSON.stringify(this.ruleForm))
                    params.stationType = params.stationType || this.stationType
                    if (this.id) {
                        gasStationApi.updateStation(params).then(res => {
                            if (res.code == 200) {
                                this.$message.success(res.msg)
                                this.close()
                                this.$parent.search()
                            } else this.$message.error(res.msg)
                        })
                    } else {
                        gasStationApi.addGasStation(params).then(res => {
                            if (res.code == 200) {
                                this.$message.success(res.msg)
                                this.close()
                                this.$parent.search()
                            } else this.$message.error(res.msg)
                        })
                    }
                } else { }
            })
        },
    }
}
</script>

<style lang="scss" scoped></style>